body {
  margin: 0;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #4a9cc3;
}
